import React from 'react';

import { Alert, useRestaurantHomeView, AdminButton } from '@fingermarkglobal/cringer.components';

import { Loading } from '@fingermarkglobal/kfc-styles';
import { PrimaryHome } from '../../components/stateless/primary-home';
import { SecondaryHome } from '../../components/stateless/secondary-home';

const CustomHomeView = ({ isShowingCustomBackground = false }) => {
  const {
    t,
    state,
    loading,
    isCurrentLang,
    onAdminActivate,
    showSecondaryHome,
    accessibilityEnabled,
    languages,
    primaryAction,
    secondaryAction,
    onChangeLanguage,
    showSecondaryAction,
    card,
    cash,
    icons,
    isOnline,
    healthMsg,
    setShowTermsAndConditionsModal,
    showTermsAndConditionsButton,
  } = useRestaurantHomeView();
  const params = {
    accessibilityEnabled,
    languages,
    isCurrentLang,
    onChangeLanguage,
    primaryAction,
    secondaryAction: showSecondaryAction && secondaryAction,
    t,
    card,
    cash,
    icons,
    healthMsg,
    isShowingCustomBackground,
    setShowTermsAndConditionsModal,
    showTermsAndConditionsButton,
  };

  return (
    <div className="home">
      <AdminButton onActivate={onAdminActivate} />
      {loading && <Loading isFirstLoad={true} />}
      {state?.matches('failure') && <Alert type="danger" message={state.context.message} />}
      {state?.matches('success') &&
        (!isOnline ? (
          <Alert type="warning" message={t('homeConnectivityIssues')} />
        ) : showSecondaryHome ? (
          <SecondaryHome {...params} />
        ) : (
          <PrimaryHome {...params} />
        ))}
    </div>
  );
};

export { CustomHomeView };
