import React from 'react';

import {
  BannerWarning,
  LocaleSelection,
  ServiceCardButton,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import DineInImage from '../../../assets/images/old/dinein.svg';
import TakeawayImage from '../../../assets/images/old/takeaway.svg';
import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultBackgroundImage from '../../../assets/images/background.jpg';

const PrimaryHome = ({
  t = () => {},
  className = '',
  languages = [],
  healthMsg = true,
  primaryAction,
  secondaryAction,
  onChangeLanguage = () => {},
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackgroundImage,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div data-test="primary-home" className={`z-10 primary-home ${className}`}>
        <div className="primary-home__loadable-image-container" data-test="home-lower">
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              className="w-4/5 mb-2"
              data-test="banner-terms"
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-health"
              image={WashHandsImage}
              className={`flex-col w-11/12 mb-24 sm:flex-row sm:w-10/12 md:w-4/5 md:mb-2 md:flex`}
            />
          )}

          <div
            data-test="home-services"
            className={`primary-home__home-services-container ${healthMsg ? 'mb-32' : 'mb-72'}`}
          >
            {!!secondaryAction ? (
              //TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916
              <ServiceCardButton
                data-test="button-secondary"
                className="w-40 bg-white shadow-lg lg:h-80 lg:w-1/2 h-60"
                onClick={secondaryAction}
              >
                <div className="primary-home__home-services-container__secondary-action-service-card-text">
                  {t('homeSecondaryAction')}
                </div>

                {/* TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
                <DineInImage className="w-32 h-32 lg:w-64" />
              </ServiceCardButton>
            ) : null}

            {!!primaryAction ? (
              // TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
              <ServiceCardButton
                data-test="button-primary"
                className="w-40 bg-white shadow-lg lg:h-80 lg:w-1/2 h-60"
                onClick={primaryAction}
              >
                <div className="primary-home__home-services-container__primary-action-service-card-text">
                  {t('homePrimaryAction')}
                </div>

                {/* TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
                <TakeawayImage className="w-32 h-32 lg:w-64" />
              </ServiceCardButton>
            ) : null}
          </div>
        </div>

        <div className="primary-home__languages-container" data-test="home-footer">
          {/* TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { PrimaryHome };
