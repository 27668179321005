import React from 'react';
import LogoImage from '../../../assets/images/logo.svg';

const Decoration = ({ showLogo = false } = {}) => {
  return (
    <div data-test="decoration" className="decoration">
      <div className="decoration__content-wrapper">
        <div className="decoration__content-wrapper__first_stripe"></div>
        <div className="decoration__content-wrapper__second_stripe"></div>
        <div className="decoration__content-wrapper__third_stripe"></div>
      </div>
      {/* TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
      {showLogo && <LogoImage data-test="logo" className="w-40 h-auto" />}
    </div>
  );
};

export { Decoration };
