// Index is for setting up framework, build tool and library specific stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Application } from './app';
import { NewRelicLogger } from '@fingermarkglobal/logger';
import { version } from './package.json';

import '@fingermarkglobal/cringer.styles/dist/generic.js';
import '@fingermarkglobal/cringer.styles/dist/kfc.js';
import './app.css';

window.logger = new NewRelicLogger({
  fields: {
    release: version,
    environment: process.env.POI_APP_ENV,
    organisation: process.env.POI_APP_LOGGER_CLIENT,
  },
  project: process.env.POI_APP_LOGGER_PROJECT,
  level: process.env.POI_APP_LOG_LEVEL,
  apiKey: process.env.POI_APP_LOGGER_NR_API_KEY,
  endpoint: process.env.POI_APP_LOGGER_NR_ENDPOINT,
  batchNumber: process.env.POI_APP_LOGGER_NR_BATCH_NUMBER,
  interval: process.env.POI_APP_LOGGER_NR_INTERVAL,
  retries: process.env.POI_APP_LOGGER_NR_RETRIES,
  retriesInterval: process.env.POI_APP_LOGGER_NR_RETRIES_INTERVAL,
});

const root = document.getElementById('app');

ReactDOM.render(<Application />, root);
