import React from 'react';

import {
  BannerWarning,
  LocaleSelection,
  ServiceCardButton,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import DineInImage from '../../../assets/images/dinein.svg';
import TakeawayImage from '../../../assets/images/takeaway.svg';
import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultHeroImage from '../../../assets/images/bucket.png';
import DefaultBackgroundImage from '../../../assets/images/background.jpg';

const SecondaryHome = ({
  t = () => {},
  className = '',
  languages = [],
  healthMsg = true,
  primaryAction,
  secondaryAction,
  onChangeLanguage = () => {},
  accessibilityEnabled = false,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackgroundImage,
        },
        hero: {
          image: DefaultHeroImage,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div data-test="secondary-home" className={`z-10 secondary-home ${className}`}>
        {!accessibilityEnabled && <HomeCustomisation.HeroImage className="w-2/5 mb-12" />}

        <div className="secondary-home__home-banner-container" data-test="home-lower">
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              className="w-4/5 mb-2"
              data-test="banner-terms"
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              data-test="health-banner"
              image={WashHandsImage}
              className="flex-col w-11/12 mb-24 sm:flex-row sm:w-10/12 md:w-4/5 md:mb-2 md:flex"
            />
          )}

          <div
            data-test="home-services"
            className={`secondary-home__home-services-container ${healthMsg ? 'mb-32' : 'mb-96'}`}
          >
            {!!secondaryAction && (
              //TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916
              <ServiceCardButton
                data-test="button-secondary"
                onClick={secondaryAction}
                className="w-40 h-40 px-4 pt-0 pb-6 shadow-lg bg-secondary lg:px-8 lg:h-80 lg:w-home-button rounded-home-button"
              >
                <div className="secondary-home__home-services-container__secondary-action-service-card-text">
                  {t('homeSecondaryAction')}
                </div>

                {/* TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
                <DineInImage className="invisible w-16 h-16 lg:w-48 lg:h-48 lg:visible" />
              </ServiceCardButton>
            )}

            {!!primaryAction && (
              // TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
              <ServiceCardButton
                data-test="button-primary"
                className="w-40 h-40 pt-0 pb-6 shadow-lg bg-secondary l lg:h-80 lg:w-home-button rounded-home-button"
                onClick={primaryAction}
              >
                <div className="secondary-home__home-services-container__secondary-action-service-card-text">
                  {t('homePrimaryAction')}
                </div>

                {/* TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
                <TakeawayImage className="invisible w-16 h-16 lg:w-48 lg:h-48 lg:visible" />
              </ServiceCardButton>
            )}
          </div>
        </div>

        <div className="secondary-home__languages-container" data-test="home-footer">
          {/* TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { SecondaryHome };
